/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { BlogCTA } from '../components/sections/blog-cta';
import { SEO } from '../components/seo';
import { Trans } from 'react-i18next';

export default function Post({ data })
{
  const post = data.mdx;
  const { frontmatter } = post;
  const featuredImage = getImage(frontmatter.featuredImage);

  return (
    <>
      <SEO
        title={frontmatter.title}
        description={frontmatter.summary}
      ></SEO>
      <Layout>
        <div className="py-20 sm:py-36 clip-path-curved bg-slate-900">
          <h1 className="mt-2 block text-3xl text-white text-center font-extrabold tracking-tight sm:text-4xl">
            <Trans>Elevating Your Mental Wellness</Trans>
          </h1>

        </div>
        <div className="relative mb-20 pt-0 sm:pt-8 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
              <svg
                className="absolute top-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
              </svg>
              <svg
                className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
              </svg>
              <svg
                className="absolute bottom-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
              </svg>
            </div>
          </div>
          <article className="relative px-4 sm:px-6 lg:px-8">

            <div className="text-lg max-w-prose mx-auto">

              <div className="grid grid-cols-2">
                <div className="block text-base font-bold text-lg text-left">
                  {frontmatter.author}
                </div>
                <div className="block text-base text-indigo-600 text-lg font-bold text-right">
                  {frontmatter.date}
                </div>
              </div>

              <GatsbyImage className="relative w-full mt-4 mb-6 rounded-lg max-h-96" image={featuredImage} alt="Featured image"></GatsbyImage>

              <h1 className="mt-2 block text-3xl text-slate-800 font-extrabold tracking-tight sm:text-4xl">
                {frontmatter.title}
              </h1>

              <div className="mt-8 prose text-slate-600 text-lg">
                <MDXProvider components={{ BlogCTA }}>
                  <MDXRenderer>{post.body}</MDXRenderer>
                </MDXProvider>
              </div>
            </div>
          </article>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    mdx(frontmatter: { slug: { eq: $slug }, language: { eq: $language } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        author
        title
        summary
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 800, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
          }
        }
      }
    }
  }
`